<template>
  <h1>Not Found</h1>
  <h4>{{ routePath }}</h4>
</template>

<script>
export default {
  name: "NotFound",
  computed: {
    routePath() {
      return this.$route.fullPath
    }
  }
}
</script>

<style scoped>

</style>
